<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";

export type HeaderAs = "div" | "header";
export type HeaderSize = "none" | "small" | "regular" | "large";
export type HeaderVariant = "between" | "inline";

type Props = {
    as?: HeaderAs;
    size?: HeaderSize;
    variant?: HeaderVariant;
};
const {
    as = "header",
    size = "regular",
    variant = undefined,
} = defineProps<Props>();

// classes
const classes: AntlerClasses<Props> = {
    base: "",
    variants: {
        size: {
            none: "mb-0",
            small: "mb-2",
            regular: "mb-4",
            large: "mb-10",
        },
        variant: {
            between: "flex gap-2 justify-between items-end",
            inline: "flex items-center gap-4",
        },
    },
};
const { aClass } = installAntlerComponent("header", { size, variant }, classes);
</script>

<template>
    <component
        :is="as"
        :class="aClass()"
    >
        <slot />
    </component>
</template>
